import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import './graficasGrandes.css';

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.7} />
        <stop offset="100%" stopColor={color} stopOpacity={0.1} />
      </linearGradient>
    </defs>
  );
}

AreaGradient.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

function getDaysInMonth(month, year) {
  const date = new Date(year, month, 0);
  const monthName = date.toLocaleDateString('en-US', {
    month: 'short',
  });
  const daysInMonth = date.getDate();
  const days = [];
  let i = 1;
  while (days.length < daysInMonth) {
    days.push(`${monthName} ${i}`);
    i += 1;
  }
  return days;
}

export default function SessionsChart() {
  const data = getDaysInMonth(4, 2024);

  const colorPalette = [
    '#4caf50', // Verde claro
    '#388e3c', // Verde medio
    '#1b5e20', // Verde oscuro
  ];

  return (
    <Card
      className="white-axes"
      variant="outlined"
      sx={{
        height: '100%',
        borderRadius: '16px',
        backgroundColor: '#0d1f0d',
        borderColor: '#b2d235',
      }}
    >
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom sx={{ color: '#fff' }}>
          Cantidad de residuos
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p" sx={{ color: '#fff' }}>
              13,277
            </Typography>
            <Chip size="small" color="success" label="+35%" />
          </Stack>
          <Typography variant="caption" sx={{ color: '#fff' }}>
            Unidades de residuos reciclados en los últimos 30 días
          </Typography>
        </Stack>
        <LineChart
          colors={colorPalette}
          xAxis={[
            {
              scaleType: 'point',
              data,
              tickInterval: (index, i) => (i + 1) % 5 === 0,
              axisLine: { stroke: '#fff' }, // Cambia el color de la línea del eje
              tick: { stroke: '#fff', color: '#fff' }, // Cambia el color de las etiquetas del eje
              tickLine: { stroke: '#fff' }, // Cambia el color de las líneas de las marcas
            },
          ]}
          yAxis={[
            {
              axisLine: { stroke: '#fff' }, // Cambia el color de la línea del eje
              tick: { stroke: '#fff', color: '#fff' }, // Cambia el color de las etiquetas del eje
              tickLine: { stroke: '#fff'}, // Cambia el color de las líneas de las marcas
            },
          ]}
          series={[
            {
              id: 'direct',
              label: 'Plástico',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                300, 900, 600, 1200, 1500, 1800, 2400, 2100, 2700, 3000, 1800, 3300,
                3600, 3900, 4200, 4500, 3900, 4800, 5100, 5400, 4800, 5700, 6000,
                6300, 6600, 6900, 7200, 7500, 7800, 8100,
              ],
            },
            {
              id: 'referral',
              label: 'Orgánicos',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                500, 900, 700, 1400, 1100, 1700, 2300, 2000, 2600, 2900, 2300, 3200,
                3500, 3800, 4100, 4400, 2900, 4700, 5000, 5300, 5600, 5900, 6200,
                6500, 5600, 6800, 7100, 7400, 7700, 8000,
              ],
            },
            {
              id: 'organic',
              label: 'Papel y Cartón',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              stackOrder: 'ascending',
              data: [
                1000, 1500, 1200, 1700, 1300, 2000, 2400, 2200, 2600, 2800, 2500,
                3000, 3400, 3700, 3200, 3900, 4100, 3500, 4300, 4500, 4000, 4700,
                5000, 5200, 4800, 5400, 5600, 5900, 6100, 6300,
              ],
              area: true,
            },
          ]}
          height={250}
          margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
          grid={{
            horizontal: true,
            stroke: '#fff', // Cambia el color de las líneas de la cuadrícula
          }}
          sx={{
            color: '#fff',
            '& .MuiLineElement-axis path': {
              stroke: '#fff !important', // Fuerza el color de los ejes a blanco
            },
            '& .MuiLineElement-tick text': {
              fill: '#fff !important', // Fuerza el color de las etiquetas de los ticks a blanco
            },
            '& .MuiLineElement-tick line': {
              stroke: '#fff !important', // Fuerza el color de las marcas de los ticks a blanco
            },
            '& .MuiLineElement-grid line': {
              stroke: '#fff !important', // Fuerza el color de las líneas de la cuadrícula a blanco
            },
            '& .MuiAreaElement-series-organic': {
              fill: "url('#organic')",
            },
            '& .MuiAreaElement-series-referral': {
              fill: "url('#referral')",
            },
            '& .MuiAreaElement-series-direct': {
              fill: "url('#direct')",
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          <AreaGradient color="#1b5e20" id="organic" /> {/* Papel y Cartón */}
          <AreaGradient color="#388e3c" id="referral" /> {/* Orgánicos */}
          <AreaGradient color="#4caf50" id="direct" /> {/* Plástico */}
        </LineChart>
      </CardContent>
    </Card>
  );
}
