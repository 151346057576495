import React from 'react';
import './ServiceCard.css';
import './Services.css';

const Services = () => {
  return (
    <div id="services" className="services-container">
      <h2>Productos y Servicios</h2>
      <div className="services-cards">
        <DataInsights />
        <AdvancedWasteMonitoring />
        <SeamlessIntegration />
        <BoostingRecyclingEfficiency />
        <InstantsAlerts />
      </div>
      <AppScreenshots />
      <DashboardSection />
    </div>
  );
};

const DataInsights = () => {
  return (
    <div className="service-card">
      <div className="service-title">
        <img src="/logoChicoNegro.png" alt="Company Logo" className="company-logo" />
        <h3>Datos</h3>
      </div>
      <p>
        Información en tiempo real sobre la clasificación de residuos.
      </p>
      <img src="/logoCardDatos.png" alt="Datos" className="service-icon" />
    </div>
  );
};

const AdvancedWasteMonitoring = () => {
  return (
    <div className="service-card">
      <div className="service-title">
        <img src="/logoChicoNegro.png" alt="Company Logo" className="company-logo" />
        <h3>IA</h3>
      </div>
      <p>
        Nuestro sistema de monitoreo de residuos impulsado por IA utiliza detección avanzada de imágenes, nuestra solución automatiza la clasificación de residuos.
      </p>
      <img src="/logoCardIA.png" alt="IA" className="service-icon" />
    </div>
  );
};

const SeamlessIntegration = () => {
  return (
    <div className="service-card">
      <div className="service-title">
        <img src="/logoChicoNegro.png" alt="Company Logo" className="company-logo" />
        <h3>Integración</h3>
      </div>
      <p>
        Conecta los datos de residuos en tiempo real a tu maquinaria y software existentes y nuevos.
      </p>
      <img src="/logoCardIntegracion.png" alt="Integración" className="service-icon" />
    </div>
  );
};

const BoostingRecyclingEfficiency = () => {
  return (
    <div className="service-card">
      <div className="service-title">
        <img src="/logoChicoNegro.png" alt="Company Logo" className="company-logo" />
        <h3>Eficiencia</h3>
      </div>
      <p>
        Mejoramos las prácticas de reciclaje, cuidando la creación.
      </p>
      <img src="/logoCardEficiencia.png" alt="Eficiencia" className="service-icon" />
    </div>
  );
};

const InstantsAlerts = () => {
  return (
    <div className="service-card">
      <div className="service-title">
        <img src="/logoChicoNegro.png" alt="Company Logo" className="company-logo" />
        <h3>Alertas</h3>
      </div>
      <p>
        Las aplicaciones web en HTML5 o CSS utilizan una mínima memoria del dispositivo, se ejecutan en un navegador y almacenan datos en un servidor.
      </p>
      <img src="/LogoCardAlertas.png" alt="Alertas" className="service-icon" />
    </div>
  );
};

const AppScreenshots = () => {
  return (
    <div className="app-screenshots">
      <h3>Aplicación Classify: tu compañero móvil</h3>
      <p>Aumenta la conciencia sobre la clasificación de residuos. Alienta prácticas sostenibles.</p>
      <div className="screenshots-container">
        <div className="screenshot">
          <img src="/app-screenshot1.PNG" alt="App Screenshot 1" />
          <p>Los usuarios rastrean residuos clasificados</p>
        </div>
        <div className="screenshot">
          <img src="/app-screenshot2.PNG" alt="App Screenshot 2" />
          <p>Ganar puntos por clasificar residuos</p>
        </div>
        <div className="screenshot">
          <img src="/app-screenshot3.png" alt="App Screenshot 3" />
          <p>Intercambiar puntos por beneficios de marcas afiliadas</p>
        </div>
      </div>
    </div>
  );
};

const DashboardSection = () => {
  return (
    <div className="dashboard-section">
      <h3>Dashboard Classify: El Compañero de Residuos de las Empresas</h3>
      <div className="dashboard-row">
        <div className="dashboard-column">
          <p>
            El Dashboard de Classify ofrece a las empresas una visión completa de sus esfuerzos de clasificación de residuos. 
            Con esta herramienta, las empresas pueden rastrear la cantidad de residuos que están clasificando en tiempo real, 
            proporcionando información valiosa sobre sus objetivos de sostenibilidad. El panel permite la fácil generación y 
            descarga de informes detallados sobre el rendimiento de la gestión de residuos, facilitando la toma de decisiones 
            informadas y la optimización de estrategias.
          </p>
        </div>
        <div className="dashboard-column">
          <img src="/fotoDashboard.png" alt="Dashboard" className="dashboard-image" />
        </div>
      </div>
    </div>
  );
};


export default Services;
