import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './Report.css';

const Report = () => {
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text('Report Title', 20, 10);
    doc.autoTable({ html: '#my-table' });
    doc.save('report.pdf');
  };

  return (
    <div className="report-container">
      <h2>Report</h2>
      <div className="document">
        {/* Contenido del documento aquí */}
        <table id="my-table">
          <thead>
            <tr>
              <th>Column 1</th>
              <th>Column 2</th>
              <th>Column 3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Row 1 Data 1</td>
              <td>Row 1 Data 2</td>
              <td>Row 1 Data 3</td>
            </tr>
            <tr>
              <td>Row 2 Data 1</td>
              <td>Row 2 Data 2</td>
              <td>Row 2 Data 3</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button onClick={generatePDF}>Download PDF</button>
    </div>
  );
};

export default Report;
