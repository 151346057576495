import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { FaHome, FaLeaf, FaCog } from 'react-icons/fa';
import { Oval } from 'react-loader-spinner';
import './Dashboard.css';
import './graficasGrandes.css';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import StatCard from './StatCard';
import HighlightedCard from './HighlightedCard';
import SessionsChart from './SessionsChart';
import PageViewsBarChart from './PageViewsBarChart';
import RecyclingBarChart from './graficaBarras';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [totalWasteData, setTotalWasteData] = useState([]);
  const [typeWasteData, setTypeWasteData] = useState([]);
  const [carbonEmissionsData, setCarbonEmissionsData] = useState([]);
  const [totalEmissionsSaved, setTotalEmissionsSaved] = useState(0);
  const [emissionsSavedLastMonth, setEmissionsSavedLastMonth] = useState(0);
  const [totalWeightRecycled, setTotalWeightRecycled] = useState(0);
  const [peopleRecycled, setPeopleRecycled] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const dataResiduosGeneral = [
    [200, 150, 100, 80, 50], // Enero: Plástico, Papel y Cartón, Orgánicos, Aluminio, Vidrio
    [180, 130, 110, 60, 70], // Febrero
    [200, 150, 150, 80, 70], // Marzo
    [220, 130, 160, 80, 90], // Abril
    [230, 110, 220, 75, 110], // Mayo
    [200, 110, 170, 90, 120], // Junio
    [190, 90, 150, 100, 115], // Julio
    [230, 150, 120, 80, 130], // Agosto
    [240, 140, 150, 110, 150], // Setiembre
    [230, 130, 110, 60, 70], // Octubre
    [200, 150, 120, 100, 150], // Noviembre 
    [240, 160, 110, 120, 160], // Diciembre
  ];

  useEffect(() => {
    const fetchCompanyHistory = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://cyclustech.com/maquina/historial_empresa?companyId=1', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(`Error: ${error.message}`);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyHistory();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const totalWaste = {};
      const typeWaste = {};
      const carbonEmissions = {};
      let totalEmissions = 0;
      let lastMonthEmissions = 0;
      let totalWeight = 0;

      data.forEach(({ wasteId, date }) => {
        const month = new Date(date).toLocaleString('default', { month: 'short', year: 'numeric' });

        if (!totalWaste[month]) totalWaste[month] = 0;
        totalWaste[month]++;

        if (!typeWaste[month]) typeWaste[month] = { month };
        if (!typeWaste[month][wasteId]) typeWaste[month][wasteId] = 0;
        typeWaste[month][wasteId]++;

        if (!carbonEmissions[month]) carbonEmissions[month] = 0;
        carbonEmissions[month] += calculateCarbonEmissions(wasteId);

        totalEmissions += calculateCarbonEmissions(wasteId);
        if (month === new Date().toLocaleString('default', { month: 'short', year: 'numeric' })) {
          lastMonthEmissions += calculateCarbonEmissions(wasteId);
        }

        totalWeight += calculateWeight(wasteId);
      });

      setTotalEmissionsSaved(totalEmissions);
      setEmissionsSavedLastMonth(lastMonthEmissions);
      setTotalWeightRecycled(totalWeight);
      setPeopleRecycled(Math.round(totalWeight * 0.7));

      const totalWasteArray = Object.entries(totalWaste).map(([month, count]) => ({ month, count }));
      setTotalWasteData(filterRecentMonths(totalWasteArray));

      const typeWasteArray = Object.values(typeWaste).map(item => ({
        month: item.month,
        plastico: item[1] || 0,
        vidrio: item[2] || 0,
        papel: item[3] || 0,
        aluminio: item[4] || 0,
      }));
      setTypeWasteData(filterRecentMonths(typeWasteArray));

      const carbonEmissionsArray = Object.entries(carbonEmissions).map(([month, emissions]) => ({ month, emissions }));
      setCarbonEmissionsData(filterRecentMonths(carbonEmissionsArray));
    }
  }, [data]);

  const calculateCarbonEmissions = (wasteId) => {
    const emissionsPerWasteType = {
      1: 2, // plástico
      2: 1.5, // vidrio
      3: 1.2, // papel
      4: 2.5, // aluminio
    };
    return roundNumber(emissionsPerWasteType[wasteId]) || 0;
  };

  const calculateWeight = (wasteId) => {
    const weightPerWasteType = {
      1: 0.5, // plástico (kg)
      2: 1.0, // vidrio (kg)
      3: 0.3, // papel (kg)
      4: 0.8, // aluminio (kg)
    };
    return weightPerWasteType[wasteId] || 0;
  };

  const filterRecentMonths = (data) => {
    const sortedData = data.sort((a, b) => new Date(b.month) - new Date(a.month));
    return sortedData.slice(0, 6).reverse();
  };

  const handleLegendClick = (material) => {
    setSelectedMaterial(selectedMaterial === material ? null : material);
  };

  const roundNumber = (num) => Math.round(num);

  return (
    // Contenedor principal con fondo gris claro
    <Box sx={{ backgroundColor: '#002400', minHeight: '100vh' , marginLeft: '185px'}}>
      <Grid container spacing={2}> {/* Controlar el espacio entre los elementos */}
        
        {/* Primera fila - StatCards */}
        <Grid item xs={12} md={3}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2}}>
            <StatCard
              title="Emisiones de Carbono Ahorradas"
              value="200 kg"
              interval="Últimos 30 días"
              trend="up"
              data={[2, 4, 3, 2, 4, 5, 7, 6, 5, 4, 3, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 14, 13, 12, 11, 13, 12, 14, 15, 17]}
            />
          </Box>
        </Grid>
  
        <Grid item xs={12} md={3}>
          <Box sx={{ backgroundColor: '#002400', borderRadius: 2}}>
            <StatCard
              title="Peso Reciclado"
              value="350 kg"
              interval="Últimos 30 días"
              trend="up"
              data={[2, 4, 3, 2, 4, 5, 7, 6, 5, 4, 3, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 14, 13, 12, 11, 13, 12, 14, 15, 17]}
            />
          </Box>
        </Grid>
  
        <Grid item xs={12} md={3}>
          <Box sx={{ backgroundColor: '#002400', borderRadius: 2}}>
            <StatCard
              title="Personas que Reciclaron"
              value="150"
              interval="Últimos 30 días"
              trend="up"
              data={[2, 4, 3, 2, 4, 5, 7, 6, 5, 4, 3, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 14, 13, 12, 11, 13, 12, 14, 15, 17]}
            />
          </Box>
        </Grid>

        {/* Get insights */}
        <Grid item xs={12} md={3}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2 }}>
            <HighlightedCard data={totalWasteData} />
          </Box>
        </Grid>

        {/* Gráfica - Gráfica de Emisiones de Carbono */}
        <Grid item xs={12} md={6}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2, className:"white-axes"}}>
            <SessionsChart data={carbonEmissionsData} selectedMaterial={selectedMaterial} onLegendClick={handleLegendClick} />
          </Box>
        </Grid>
  
        {/* Gráfica - Resumen de Residuos por Material */}
        <Grid item xs={12} md={6}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2 }}>
            <PageViewsBarChart data={typeWasteData} />
          </Box>
        </Grid>

        {/* Gráfica - Resumen de Residuos por Material */}
        <Grid item xs={12} md={12}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2 }}>
          <RecyclingBarChart
            title="Residuos Reciclados"
            subtitle="Datos de reciclaje de los últimos 12 meses para cada categoria"
            data={dataResiduosGeneral}
            sx={{ height: '100%' }}
          />
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Dashboard;
