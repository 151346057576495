import React from 'react';
import './ValueChain.css';

const ValueChain = () => {
  return (
    <section id="value-chain" className="value-chain-section">
      <h2 className="section-title">Smart assessment throughout the value chain.</h2>
      <div className="image-container">
        <img src="/valueChain.png" alt="Value Chain" className="value-chain-image" /> {/* Asegúrate de tener la imagen en la carpeta public */}
      </div>
    </section>
  );
};

export default ValueChain;


