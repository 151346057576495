import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.7} />
        <stop offset="100%" stopColor={color} stopOpacity={0.1} />
      </linearGradient>
    </defs>
  );
}

export default function PageViewsBarChart() {
  const theme = useTheme();
  const colorPalette = [
    '#4caf50', // Verde claro
    //'#388e3c', Verde medio
    '#1b5e20', // Verde oscuro
  ];

  return (
    <Card variant="outlined" sx={{
      height: '100%',
      borderRadius: '16px',
      backgroundColor: '#0d1f0d',
      borderColor: '#b2d235',
    }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom sx={{ color: '#fff' }}>
          Usuarios de la maquina
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p" sx={{ color: '#fff' }}>
              1.3M
            </Typography>
            <Chip size="small" color="error" label="-8%" />
          </Stack>
          <Typography variant="caption" sx={{ color: '#fff' }}>
            Comparación entre usuarios registrados y no registrados
          </Typography>
        </Stack>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={[
            {
              borderColor: '#fff',
              scaleType: 'band',
              categoryGapRatio: 0.5,
              data: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
            },
          ]}
          series={[
            {
              id: 'registrados',
              label: 'Registrados',
              data: [2234, 3872, 2998, 4125, 3357, 2789, 2998],
              stack: 'A',
            },
            {
              id: 'noRegistrados',
              label: 'No registrados',
              data: [3098, 4215, 2384, 2101, 4752, 3593, 2384],
              stack: 'A',
            },
            
          ]}
          height={250}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          <AreaGradient color="#1b5e20" id="registrados" /> 
          <AreaGradient color="#4caf50" id="noRegistrados" /> 
        </BarChart>
      </CardContent>
    </Card>
  );
}