import React, { useState } from 'react';
import './Settings.css';

const Settings = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSave = () => {
    // Aquí puedes implementar la lógica para guardar los cambios
    console.log('Save button clicked');
  };

  return (
    <div className="settings-container">
      <h1>Settings</h1>
      <div className="settings-form">
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="save-button" onClick={handleSave}>
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default Settings;
