import React from 'react';
import './ContactUs.css';

function ContactUs() {
  return (
    <div className="contact-container">
      <div className="contact-header">
        <h2>Contactanos</h2>
        <h1>No dudes en escribirnos!</h1>
      </div>
      <div className="contact-content">
        <div className="contact-info">
          <div className="contact-details">
            <div>
              <h3>Dirección</h3>
              <p>Cam. Regulo 4450, 12400</p>
              <p>Montevideo, Departamento de Montevideo, Uruguay</p>
            </div>
            <div>
              <h3>Contactos</h3>
              <p>Uy: +598 96 689 795</p>
              <p>Arg: + 54 9 11 5621 5105</p>
              <p>+54 9 11 2457 1697</p>
            </div>
            <div>
              <h3>Mail</h3>
              <p>smartrecycle.info@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <form>
            <input type="text" name="name" placeholder="Name" />
            <input type="email" name="email" placeholder="Email" />
            <input type="text" name="subject" placeholder="Subject" />
            <textarea name="message" placeholder="Message"></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
