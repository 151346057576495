import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import ValueChain from './components/ValueChain';
import ContactUs from './components/ContactUs';
import SupportedBy from './components/SupportedBy';
import Dashboard from './dashboardComponents/Dashboard';
import Report from './dashboardComponents/Report';
import Settings from './dashboardComponents/Settings';
import Layout from './dashboardComponents/Layout';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={
            <>
              <Header onLoginSuccess={handleLogin} />
              <Home />
              <Services />
              <ValueChain />
              <AboutUs />
              <ContactUs />
              <SupportedBy />
            </>
          } />

          {/* Envolvemos las rutas del dashboard con el componente Layout */}
          {isLoggedIn ? (
            <Route path="/dashboard" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="report" element={<Report />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
