import React from 'react';
import './Home.css';
import { Link as ScrollLink } from 'react-scroll';

const Home = () => {
  return (
    <div className="home-container">
      {/* Sección de la imagen con las montañas y el logo de Classify */}
      <section className="hero-section">
        <div className="hero-image">
          <img src="/fotoClassify2.jpg" alt="Mountains with Classify logo" />
        </div>
      </section>

      {/* Sección de la imagen de la máquina con el texto */}
      <section className="info-section">
        <div className="info-image">
          <img src="/fotoMaquina.jpg" alt="I-trash machine" />
          {/* El contenido del texto debe estar dentro del contenedor de la imagen */}
          <div className="info-content">
            <h1>I-trash: Nuestra basura inteligente basada en IA</h1>
            <p>
              Nuestra solución basada en IA simplifica el proceso de clasificación de residuos para las personas. Al hacerlo, podemos eliminar la contaminación cruzada, mejorar las tasas de reciclaje, proporcionar información valiosa para optimizar la gestión de residuos y ayudar a las empresas a alcanzar sus objetivos ambientales. Nuestro sistema I-trash está diseñado para ser fácil de usar y altamente efectivo, haciendo que la clasificación de residuos sea una parte fluida de la vida cotidiana.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;



