import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

export default function RecyclingBarChart({ title, subtitle, data }) {
  const theme = useTheme();

  // Paleta de colores para las 5 categorías
  const colorPalette = [
    '#F4F4F4',
    '#BBFF42', // Plástico (Verde claro)
    '#7DA2CF', // Papel y cartón (Verde medio)
    '#193863', // Orgánicos (Amarillo)
    '#001600', // Aluminio (Amarillo oscuro)
  ];

  // Datos de ejemplo, puedes pasar estos datos como props
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const categories = ['Plástico', 'Papel y Cartón', 'Orgánicos', 'Aluminio', 'Vidrio'];

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        borderRadius: '16px',
        backgroundColor: '#0d1f0d',
        borderColor: '#b2d235',
      }}
    >
      <CardContent>
        <Typography component="h1" variant="title" gutterBottom sx={{ color: '#fff' }}>
          {title}
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Typography variant="caption" sx={{ color: '#fff', marginBottom: 2 }}>
            {subtitle}
          </Typography>
        </Stack>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={[
            {
              borderColor: '#fff',
              scaleType: 'band',
              categoryGapRatio: 0.5,
              data: months,
            },
          ]}
          series={categories.map((category, index) => ({
            id: category,
            label: category,
            data: data.map((monthData) => monthData[index]), // Extrae los datos para cada categoría por mes
          }))}
          height={300}
          margin={{ left: 50, right: 0, top: 20, bottom: 40 }}
          grid={{ horizontal: true }}
          
        />
      </CardContent>
    </Card>
  );
}
