import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      {/* Sección de la imagen de fondo con el texto */}
      <section id="about-us" className="about-us-section">
        <div className="about-us-image">
          <img src="/fotoMaquina.jpg" alt="Recycling machine" />
          {/* El contenido del texto debe estar dentro del contenedor de la imagen */}
          <div className="about-us-content">
            <h1>Sobre Nosotros</h1>
            <p>
              En Classify, estamos dedicados a transformar la gestión de residuos con soluciones innovadoras y eficientes.
              Nuestra misión es mejorar cada interacción que las personas tienen con tu marca construyendo marcas con propósito 
              que generen un impacto significativo. Con nuestra tecnología avanzada, proporcionamos datos precisos para optimizar 
              tus estrategias de gestión de residuos, permitiendo decisiones informadas que minimicen el impacto ambiental.
            </p>
            <p>
              Nuestro equipo está comprometido a ofrecer integración perfecta, flexibilidad y sistemas avanzados de monitoreo de residuos. 
              Únete a nosotros en nuestra misión de mejorar la eficiencia de clasificación, mejorar los esfuerzos de reciclaje y promover el 
              cuidado de la creación.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
