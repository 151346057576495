import React from 'react';
import './SupportedBy.css';

function SupportedBy() {
  return (
    <div className="supported-container">
      <h3>Supported by:</h3>
      <div className="supported-logos">
        <img src="LogoANDE.png" alt="ANDE Logo" />
        <img src="LogoANII.jpg" alt="ANII Logo" />
        <img src="LogoEmprelatam.png" alt="Emprelatam Logo" />
        <img src="LogoInitum.png" alt="Initium Logo" />
        <img src="LogoIAE.png" alt="IAE Logo" />
      </div>
    </div>
  );
}

export default SupportedBy;
