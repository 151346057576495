import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function HighlightedCard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card variant="outlined" sx={{ height: '100%', borderRadius: '16px', backgroundColor: '#002400', borderColor: '#b2d235' }}>
      <CardContent>
        <InsightsRoundedIcon sx={{ color: '#fff', fontSize: '25px'}}/>
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ color: '#fff', fontSize: '15px' }}
        >
          EXPLORA TUS DATOS
        </Typography>
        <Typography sx={{ color: '#fff', mb: '10px', fontSize: '12px'}}>
          Descubra el rendimiento y la información sobre sus residuos con nuestra magia de datos.
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="#b2d235"
          backgroundColor="#BBFF42"
          endIcon={<ChevronRightRoundedIcon sx={{ color: '#b2d235' }}/>}
          fullWidth={isSmallScreen}
          sx = {{color:'#b2d235'}}
        >
          Ver reportes
        </Button>
      </CardContent>
    </Card>
  );
}