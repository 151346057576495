import React, { useState } from 'react';
import { Link } from 'react-scroll';
import LoginModal from './LoginModal';
import './Header.css';

const Header = ({ onLoginSuccess }) => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header>
      <div className="logo">
        <img src="/logoChicoFluo.png" alt="SmartRecycle Logo" /> {/* Asegúrate de tener el logo en la carpeta public */}
      </div>
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        &#9776;
      </div>
      <nav className={isMobileMenuOpen ? 'mobile-menu-open' : ''}>
        <ul>
          <li><Link to="about-us" smooth={true} duration={500} onClick={toggleMobileMenu}>Sobre Nosotros</Link></li>
          <li><Link to="services" smooth={true} duration={500} onClick={toggleMobileMenu}>Servicios</Link></li>
          <li><Link to="value-chain" smooth={true} duration={500} onClick={toggleMobileMenu}>Cadena de valor</Link></li>
          <li><span onClick={openLoginModal} className="login-button">Login</span></li>
        </ul>
      </nav>
      <LoginModal isOpen={isLoginModalOpen} onRequestClose={closeLoginModal} onLoginSuccess={onLoginSuccess} />
    </header>
  );
};

export default Header;
